import { LOGIN_SUCCESS, LOGOUT, FORGOT_SUCCESS, RESET_PASSWORD } from "../actions/types"

const initialState = {
    token: '',
    isLoggedIn: false,
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                ...payload,
                isLoggedIn: true,
            };
        case LOGOUT:
            return {
                ...state,
                ...action.payload,
                isLoggedIn: false,
            };
        case FORGOT_SUCCESS:
            return {
                ...state,
                ...action.payload,
                isLoggedIn: false,
            };
        case RESET_PASSWORD:
            return {
                ...state,
                ...action.payload,
                isLoggedIn: false,
            };
        default:
            return state
    }
}