import {GET_ADMIN_DATA, CHANGE_ADMIN_PASSWORD} from "../actions/types"

const initialState = {
    data: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ADMIN_DATA:
            return {
                ...state,
                data: action.payload
            };
        case CHANGE_ADMIN_PASSWORD:
            return {
                ...state,
            }
        default:
            return state
    }
}